export default function AboutContent() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-indigo-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-pink-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-yellow-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center font-display leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our Story
            </span>
          </h1>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p>
              TwoWeeks was started by a group of software engineers frustrated
              that all the work management solutions they encountered seemed to
              be disconnected from reality and didn't help teams where they
              needed it the most. Managing work in a dynamic environment was
              difficult enough - using static tools to do so made it nearly
              impossible to keep up with constant change.
            </p>

            <p>
              As things changed, more and more work was required to manage it
              all. Static platforms were divorced from reality and didn't give
              real-world scenarios. We found ourselves continuously stuck
              between what was desired and what was feasible. Due dates were
              always at odds with delivery capabilities. Because of this, teams
              worked harder and longer, leading to burnout. As processes
              decayed, visibility faded from view.
            </p>

            <p>We wanted to end the madness</p>
            <p>
              That's why we built TwoWeeks. To take the complexity out of
              managing product development work. To provide the visibility you
              need to make good decisions. To give everyone a better way to work
              so people can love what they do again.
            </p>
            <figure>
              <img
                className="w-full rounded-lg"
                src="img/full_of_stars.jpg"
                alt=""
                width={1310}
                height={873}
              />
              <figcaption>
                Photo from{" "}
                <a href="https://unsplash.com/@grakozy" target="blank">
                  Greg Rakozy
                </a>
                , Spiral Jetty, United States
              </figcaption>
            </figure>
            <h3 className="tracking-tight font-display font-bold">
              Our Purpose
            </h3>
            <p>To help organizations focus on what matters to them</p>

            <h3 className="tracking-tight font-display font-bold">
              Our Vision
            </h3>
            <p>A better way of delivering products</p>

            <h3 className="tracking-tight font-display font-bold">
              Our Values
            </h3>

            <ul>
              <li>We evolve</li>
              <li>We believe that integrity is the only path to trust</li>
              <li>We find richness from diversity, strength through unity</li>
              <li>We are curious, we never stop learning</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
