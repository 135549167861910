import AboutContent from "./AboutContent";
import { AboutHeader } from "./AboutHeader";
import Footer from "../../elements/Footer";

export function About() {
  return (
    <div>
      <AboutHeader />
      <AboutContent />
      <Footer />
    </div>
  );
}
