import { PrivacyContent } from "./PrivacyContent";
import { PrivacyHeader } from "./PrivacyHeader";
import Footer from "../../elements/Footer";

export function Privacy() {
  return (
    <div>
      <PrivacyHeader />
      <PrivacyContent />
      <Footer />
    </div>
  );
}
