import Footer from "../../elements/Footer";
import ContactForm from "./ContactForm";
import { ContactHeader } from "./ContactHeader";

export function Contact() {
  return (
    <div>
      <ContactHeader />
      <ContactForm />
      <Footer />
    </div>
  );
}
