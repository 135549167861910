import { CheckIcon } from "@heroicons/react/20/solid";

// const features = [
//   {
//     name: "Seven-Point Automation for Schedules",
//     description:
//       "Schedules are produced in seconds. No matter how large or small the team.",
//   },
//   {
//     name: "Automated Change Management",
//     description:
//       "Stop wondering what will happen when you have to change priorities. Now you can run prioritization scenarios to understand the outcomes of potential business decisions before you commit to them. As you change priorities in the application, schedules respond dynamically to show you new delivery dates for work items, milestones, and initiatives.",
//   },
//   {
//     name: "Go Beyond Delivery Dates",
//     description:
//       "TwoWeeks.io adjusts schedules in real time to continuously optimize work progression. You’ll get insight with delivery estimates for every work item in progress so you can manage delivery dates. Our testing showed 80% accuracy in predicting when initiatives would be done even in the most complex environments.",
//   },
// ];

export function SectionOnDark() {
  return (
    <div className="bg-white" id="autopilot">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              The First in AI Project Navigation
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              TwoWeeks.io is the first AI enhanced project navigation platform
              purpose built to support agile business practices at scale.
              Powered by a scheduling AI, we&apos;re different from other
              solutions in a few ways.
            </p>
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-6 text-base leading-7 text-gray-600 lg:gap-y-8">
            <div className="relative pl-9">
              <dt className="font-semibold text-gray-900">
                <CheckIcon
                  className="absolute left-0 top-1 h-5 w-5 text-sky-500"
                  aria-hidden="true"
                />
                Seven-Point Automation for Schedules
              </dt>
              <dd className="mt-2 space-y-3">
                <p>
                  Take the manual work out of planning intervals and sprint
                  planning. Autopilot builds schedules automatically for
                  everyone to deliver the most efficient sequences of work
                  across the organization based on seven points of automation:
                </p>
                <ol className="list-decimal pl-12">
                  <li>Strategic priorities for initiatives</li>
                  <li>Resource availability</li>
                  <li>Time zones</li>
                  <li>Task and cross-project dependencies</li>
                  <li>Existing workloads</li>
                  <li>Three-point job size estimation</li>
                  <li>Real-time work progress </li>
                </ol>
                <p>
                  Schedules are generated in milliseconds for each resource
                  whether you have a team of 10 or a team of 1000.
                </p>
              </dd>
            </div>

            <div className="relative pl-9">
              <dt className="font-semibold text-gray-900">
                <CheckIcon
                  className="absolute left-0 top-1 h-5 w-5 text-sky-500"
                  aria-hidden="true"
                />
                Automated Change Management
              </dt>
              <dd className="mt-2">
                Stop wondering what will happen when you have to change
                priorities. Now you can run prioritization scenarios to
                understand the outcomes of potential business decisions before
                you commit to them. As you change priorities in the application,
                schedules respond dynamically to show you new delivery dates for
                work items, milestones, and initiatives.
              </dd>
            </div>

            <div className="relative pl-9">
              <dt className="font-semibold text-gray-900">
                <CheckIcon
                  className="absolute left-0 top-1 h-5 w-5 text-sky-500"
                  aria-hidden="true"
                />
                Go Beyond Delivery Dates
              </dt>
              <dd className="mt-2">
                TwoWeeks.io adjusts schedules in real time to continuously
                optimize work progression. You&apo;ll get insight with delivery
                estimates for every work item in progress so you can manage
                delivery dates. Our testing showed 80% accuracy in predicting
                when initiatives would be done even in the most complex
                environments.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
