export default function BottomDescription() {
  return (
    <div className="overflow-hidden">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
          <div className="lg:pt-4 lg:pr-4">
            <div className="lg:max-w-lg">
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Deliver Immediate Business Agility
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                By automating the most complex parts of scheduling and change
                management, TwoWeeks.io creates business agility for enterprises
                like never before. Organizational competencies are built-into
                the workflows within the platform so you won&apos;t need to
                conduct cultural changes to implement agile methodologies. The
                technology supports all the aspects of Agile while removing
                their complexity.
              </p>
            </div>
          </div>
          <img
            src="/img/screenshot-swimlanes.png"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
