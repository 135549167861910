import { useState } from "react";
import { Switch } from "@headlessui/react";
import { getProofOfWork } from "./getProofOfWork";
import { Notification } from "./Notification";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function ContactForm() {
  const urlParams = new URLSearchParams(window.location.search);

  const [agreed, setAgreed] = useState(false);
  const [email, setEmail] = useState(urlParams.get("email") || "");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("US");
  const [message, setMessage] = useState("");
  const [topic, setTopic] = useState("Contact customer support");
  const [companySize, setCompanySize] = useState("1 - 9");
  const [department, setDepartment] = useState("Engineering");

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const [proof, hash] = await getProofOfWork();

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URI + "/contact_us",
        {
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          redirect: "follow",
          body: JSON.stringify({
            email,
            firstName,
            lastName,
            companyName,
            countryCode,
            phoneNumber,
            message,
            topic,
            companySize,
            department,
            proof,
            hash,
          }),
        }
      );
      if (response.status === 201) {
        setShowSuccess(true);
        setMessage("");
        event.currentTarget.reset();
      } else {
        setShowError(true);
      }
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <Notification
        title="Your message has been sent!"
        subTitle="We'll respond to you shortly."
        show={showSuccess}
        setShow={setShowSuccess}
      />
      <Notification
        title="Error"
        subTitle="Something went wrong, try again later"
        show={showError}
        setShow={setShowError}
        isBad
      />
      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute left-full transform translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="blue-squares"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-indigo-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#blue-squares)" />
        </svg>
        <svg
          className="absolute right-full bottom-0 transform -translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="pink-squares"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-pink-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#pink-squares)" />
        </svg>
        <div className="text-center">
          <h2 className="text-3xl font-display font-bold tracking-tight text-gray-900 sm:text-4xl">
            Contact Us
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            Questions? We're here to help!
          </p>
        </div>
        <div className="mt-12">
          <form
            method="GET"
            onSubmit={(event) => {
              if (agreed) {
                onSubmit(event);
              }
              event.preventDefault();
            }}
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div>
              <label
                htmlFor="first-name"
                className="text-sm font-medium text-gray-700 flex flex-row justify-between "
              >
                <span>
                  First name <span className="text-red-600">*</span>
                </span>
                <span className="text-gray-400 font-normal">required</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="first-name"
                  onChange={(event) => setFirstName(event.currentTarget.value)}
                  value={firstName}
                  id="first-name"
                  autoComplete="given-name"
                  autoFocus
                  required
                  className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last-name"
                className="text-sm font-medium text-gray-700 flex flex-row justify-between "
              >
                <span>
                  Last name <span className="text-red-600">*</span>
                </span>
                <span className="text-gray-400 font-normal">required</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="last-name"
                  required
                  onChange={(event) => setLastName(event.currentTarget.value)}
                  value={lastName}
                  id="last-name"
                  autoComplete="family-name"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="text-sm font-medium text-gray-700 flex flex-row justify-between"
              >
                <span>
                  Company <span className="text-red-600">*</span>
                </span>
                <span className="text-gray-400 font-normal">required</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="company"
                  required
                  id="company"
                  onChange={(event) =>
                    setCompanyName(event.currentTarget.value)
                  }
                  value={companyName}
                  autoComplete="organization"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="text-sm font-medium text-gray-700 flex flex-row justify-between"
              >
                <span>
                  Work Email <span className="text-red-600">*</span>
                </span>
                <span className="text-gray-400 font-normal">required</span>
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  required
                  type="email"
                  autoComplete="email"
                  onChange={(event) => setEmail(event.currentTarget.value)}
                  value={email}
                  className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="phone-number"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <label htmlFor="country" className="sr-only">
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    onChange={(event) =>
                      setCountryCode(event.currentTarget.value)
                    }
                    value={countryCode}
                    className="h-full py-0 pl-4 pr-8 border-transparent bg-transparent text-gray-500 focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                  >
                    <option>US</option>
                    <option>CA</option>
                    <option>EU</option>
                  </select>
                </div>
                <input
                  type="text"
                  name="phone-number"
                  id="phone-number"
                  onChange={(event) =>
                    setPhoneNumber(event.currentTarget.value)
                  }
                  value={phoneNumber}
                  autoComplete="tel"
                  className="py-3 px-4 block w-full pl-20 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  placeholder="+1 (555) 987-6543"
                />
              </div>
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="companySize"
                className="text-sm font-medium text-gray-700 flex flex-row justify-between"
              >
                Company Size
              </label>
              <select
                id="companySize"
                name="companySize"
                required
                onChange={(event) => setCompanySize(event.currentTarget.value)}
                value={companySize}
                className="mt-1 block w-full pl-3 pr-10 py-3 text-base border-gray-300 focus:outline-none text-gray-500 focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
              >
                <option>1 - 9</option>
                <option>10 - 19</option>
                <option>20 - 49</option>
                <option>50 - 99</option>
                <option>100 - 249</option>
                <option>250 - 999</option>
                <option>1000+</option>
              </select>
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="department"
                className="text-sm font-medium text-gray-700 flex flex-row justify-between"
              >
                Your Department
              </label>
              <select
                id="department"
                name="department"
                required
                onChange={(event) => setDepartment(event.currentTarget.value)}
                value={department}
                className="mt-1 block w-full pl-3 pr-10 py-3 text-base border-gray-300 focus:outline-none text-gray-500 focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
              >
                <option>Engineering</option>
                <option>Product</option>
                <option>IT Operations and Technology</option>
                <option>Other</option>
              </select>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="topic"
                className="text-sm font-medium text-gray-700 flex flex-row justify-between"
              >
                Topic
              </label>
              <select
                id="topic"
                required
                name="topic"
                onChange={(event) => setTopic(event.currentTarget.value)}
                value={topic}
                className="mt-1 block w-full pl-3 pr-10 py-3 text-base border-gray-300 focus:outline-none text-gray-500 focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
              >
                <option>Contact customer support</option>
                <option>Contact sales</option>
                <option>Help getting started</option>
                <option>Billing</option>
                <option>Other</option>
              </select>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="text-sm font-medium text-gray-700 flex flex-row justify-between"
              >
                <span>
                  Message <span className="text-red-600">*</span>
                </span>
                <span className="text-gray-400 font-normal">required</span>
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  required
                  name="message"
                  rows={4}
                  className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                  onChange={(event) => setMessage(event.currentTarget.value)}
                  value={message}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <Switch
                    checked={agreed}
                    onChange={setAgreed}
                    className={classNames(
                      agreed ? "bg-indigo-600" : "bg-gray-200",
                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    )}
                  >
                    <span className="sr-only">Agree to policies</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        agreed ? "translate-x-5" : "translate-x-0",
                        "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                      )}
                    />
                  </Switch>
                </div>
                <div className="ml-3">
                  <p className="text-base text-gray-500">
                    By selecting this, you agree to the{" "}
                    <a
                      href="/privacy"
                      className="font-medium text-gray-700 underline"
                      target="blank"
                    >
                      Privacy Policy and Cookie Policy
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:col-span-2">
              <button
                type="submit"
                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-400 disabled:text-white"
                disabled={!agreed}
              >
                Let's talk
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
