export default function RevolutionIsHere() {
  return (
    <div className="bg-gray-800">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-around">
        <h2 className="space-y-2 tracking-tight">
          <span className=" md:text-4xl text-3xl block font-bold text-white">
            Do you want to know more?
          </span>
          <span className="text-2xl block font-semibold  text-teal-300">
            Experience our demo today!
          </span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a
              href="https://app.trytwoweeks.com"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-500 hover:bg-teal-600"
            >
              Try the TwoWeeks Demo
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
