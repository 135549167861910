import BottomLine from "./BottomLine";
import Footer from "../../elements/Footer";
import Hero from "./Hero";
import Intersticial from "./Intersticial";
import MiddleDescription from "./MiddleDescription";
import RevolutionIsHere from "./RevolutionIsHere";
import TopDescription from "./TopDescription";
import BottomDescription from "./BottomDescription";
import Testimonial from "./Testimonial";
import { SectionOnDark } from "./SectionsOnDark";
// import { ForseeTheFuture } from "./ForseeTheFuture";

export function Home() {
  return (
    <Hero>
      <TopDescription />
      <SectionOnDark />
      <Intersticial />
      <BottomDescription />
      <RevolutionIsHere />
      <MiddleDescription />
      {/* <ForseeTheFuture /> */}
      <Testimonial />
      {/* <FAQ /> */}
      <BottomLine />
      <Footer />
    </Hero>
  );
}
