import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import "./Hero.css";

const navigation = [
  {
    name: "Product",
    href: "#product",
  },
  { name: "Autopilot", href: "#autopilot" },
  { name: "Blog", href: "/posts/index.html" },
  { name: "Demo", href: "https://app.trytwoweeks.com" },
  { name: "Forum", href: "https://forum.twoweeks.io" },
];

export default function Hero(props: React.PropsWithChildren) {
  return (
    <div className="relative overflow-hidden bg-gray-900 lg:from-black lg:bg-gradient-to-tr lg:via-gray-900 lg:to-gray-600">
      <Popover as="header" className="relative">
        <div className="bg-transparent pt-6">
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="/" className="flex flex-row space-x-2 items-center">
                  <img
                    src="/img/logo-on-dark.png"
                    alt="A blue and white circles intertwined"
                    className="w-10 h-10"
                  />
                  <span className="sr-only">TwoWeeks.io</span>
                  <span className="text-gray-200 font-logo font-semibold text-3xl">
                    TwoWeeks.io
                  </span>
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-8 md:flex md:ml-10">
                {navigation.map((item) =>
                  item.href.startsWith("#") ? (
                    <a
                      key={item.name}
                      href={item.href}
                      className="text-base font-medium text-white hover:text-gray-300"
                      onClick={(event) => {
                        document
                          .getElementById(item.href.slice(1))
                          ?.scrollIntoView({ behavior: "smooth" });
                        event.preventDefault();
                      }}
                    >
                      {item.name}
                    </a>
                  ) : (
                    <a
                      key={item.name}
                      href={item.href}
                      className="text-base font-medium text-white hover:text-gray-300"
                    >
                      {item.name}
                    </a>
                  )
                )}
              </div>
            </div>
            <div className="hidden md:flex md:items-center md:space-x-6">
              <a
                href={process.env.REACT_APP_WEBAPP_URI}
                className="text-base font-medium text-white hover:text-gray-300"
              >
                Sign in
              </a>
              <a
                href={`${process.env.REACT_APP_WEBAPP_URI}/auth/register`}
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
              >
                Sign up
              </a>
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top md:hidden"
          >
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div className="flex flex-row items-center space-x-2">
                  <img
                    src="/img/logo-on-light.png"
                    alt="A blue and white circles intertwined"
                    className="w-8 h-8"
                  />
                  <span className="text-gray-900 font-logo font-bold text-2xl">
                    TwoWeeks.io
                  </span>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-600">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6 px-5">
                  <a
                    href="/beta"
                    className="block text-center w-full py-3 px-4 rounded-md shadow bg-sky-600 text-white font-medium hover:bg-sky-700"
                  >
                    Join the BETA
                  </a>
                </div>
                <div className="mt-6 px-5">
                  <p className="text-center text-base font-medium text-gray-500">
                    Existing customer?{" "}
                    <a
                      href={process.env.REACT_APP_WEBAPP_URI}
                      className="text-gray-900 hover:underline"
                    >
                      Login
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <main>
        <div className="pt-10  sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-24">
                  <h1 className="mt-4 font-display tracking-tight font-semibold text-white sm:mt-5 sm:text-2xl lg:mt-6 xl:text-4xl">
                    <div className="block !leading-tight space-y-4 text-center md:text-left">
                      <span className="text-gray-200 text-2xl lg:text-4xl">
                        Others do project management.
                      </span>
                      <span className="text-teal-400 block text-3xl lg:text-5xl">
                        We do project navigation.
                      </span>
                    </div>
                  </h1>

                  <div className="mt-10 sm:mt-12">
                    <form
                      action={`${process.env.REACT_APP_WEBAPP_URI}/auth/register`}
                      className="sm:max-w-xl sm:mx-auto lg:mx-0"
                    >
                      <div className="sm:flex items-center justify-end">
                        <p className="text-2xl text-gray-400"></p>
                        <div className="mt-3 sm:mt-0 sm:ml-3">
                          <a
                            href="https://app.trytwoweeks.com"
                            className=" text-center block w-full py-3 px-4 rounded-md shadow bg-teal-500 text-white font-medium hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-300 focus:ring-offset-gray-900"
                          >
                            Try the Demo and see it in action.
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-12 -mb-16 sm:-mb-28 lg:m-0 lg:relative">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                  <img
                    className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none floating"
                    src="/img/businessman-doing-meditation.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white space-y-12 sm:space-y-16">
          {props.children}
        </div>
      </main>
    </div>
  );
}
