import { TOSContent } from "./TOSContent";
import { TOSHeader } from "./TOSHeader";
import Footer from "../../elements/Footer";

export function TOS() {
  return (
    <div>
      <TOSHeader />
      <TOSContent />
      <Footer />
    </div>
  );
}
