export function TOSContent() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto prose">
          <h1>Terms Of Service</h1>
          <p>Effective March 1st, 2023</p>

          <p>
            Briga Labs, LLC DBA TwoWeeks. ("the Company", "we", "our", or "us")
            offers a cutting-edge software development platform called TwoWeeks
            (the "Software") along with support services (collectively, the
            "Services") that facilitate collaboration, work organization, and
            execution. Our proprietary algorithms and machine learning
            capabilities further assist development teams throughout the
            software development lifecycle. The Services are available online
            at, but not limited to, www.twoweeks.io, [insert documentation link]
            (the "Websites").
          </p>
          <p>
            These Terms of Service ("Terms") are an agreement between you,
            acting with the full legal authority to represent the entity you are
            acting on behalf of ("the Customer", "you", or "your"), and the
            Company, and govern the use of our Services and Websites. By
            accessing or using the Services or Websites, you agree to be bound
            by these Terms.
          </p>
          <p>
            These Terms become effective on the date you first click the
            acceptance button (or similar button) when registering for an
            account and placing an order, and are binding upon that action.
          </p>
          <p>
            We may revise these Terms from time to time at our sole discretion.
            Changes will be posted on our Websites; if the changes are
            significant or represent a material change, as determined by us, we
            will provide you with reasonable notice of the upcoming changes
            before they become effective by sending written notice to the email
            on file associated with the Owner of your company's account. If you
            do not agree or cannot comply with the updated Terms, you agree to
            stop using the Services and Websites. Your continued use of the
            Services and Websites after any changes to the Terms constitutes
            your acceptance of the updated Terms.
          </p>
          <h2>TERMS AND CONDITIONS</h2>
          <h3>1. SERVICES PROVISION</h3>
          <p>
            1.1 Subject to the terms of this Agreement, the Company will use
            commercially reasonable efforts to provide the Customer with the
            Services. During the registration process, the Customer will
            identify an administrative username and password for their Company
            account. The Company reserves the right to refuse registration of,
            or cancel passwords it deems inappropriate.
          </p>
          <p>
            1.2 Subject to the terms herein, the Company will provide the
            Customer with reasonable technical support services according to the
            Company's standard practices.
          </p>
          <h3>2. RESTRICTIONS AND RESPONSIBILITIES</h3>
          <p>
            2.1 The Customer will not, directly or indirectly: reverse engineer,
            decompile, disassemble or otherwise attempt to discover the source
            code, object code, or underlying structure, ideas, know-how, or
            algorithms relevant to the Services or any software, documentation,
            or data related to the Services; modify, translate, or create
            derivative works based on the Services or any Software (except as
            expressly permitted by the Company or authorized within the
            Services); use the Services or any Software for timesharing or
            service bureau purposes or for the benefit of a third party; or
            remove any proprietary notices or labels.
          </p>
          <p>
            2.2 Furthermore, the Customer may not remove or export from the
            United States, or allow the export or re-export of the Services,
            Software, or anything related thereto, or any direct product
            thereof, in violation of any restrictions, laws, or regulations of
            the United States Department of Commerce, the United States
            Department of Treasury Office of Foreign Assets Control, or any
            other United States or foreign agency or authority. As defined in
            FAR section 2.101, the Software and documentation are "commercial
            items" and according to DFAR section 252.227-7014(a)(1) and (5) are
            deemed to be "commercial computer software" and "commercial computer
            software documentation." Consistent with DFAR section 227.7202 and
            FAR section 12.212, any use, modification, reproduction, release,
            performance, display, or disclosure of such commercial software or
            commercial software documentation by the U.S. Government will be
            governed solely by the terms of this Agreement and will be
            prohibited except to the extent expressly permitted by the terms of
            this Agreement.
          </p>
          <p>
            2.3 The Customer represents, covenants, and warrants that they will
            use the Services only in compliance with the Company's standard
            published policies then in effect (the "Policy") and all applicable
            laws and regulations. The Customer agrees to indemnify and hold
            harmless the Company against any damages, losses, liabilities,
            settlements, and expenses (including without limitation costs and
            attorneys' fees) in connection with any claim or action that arises
            from an alleged violation of the foregoing or otherwise from the
            Customer's use of the Services. Although the Company has no
            obligation to monitor the Customer's use of the Services, the
            Company may do so and may prohibit any use of the Services it
            believes may be (or alleged to be) in violation of the foregoing.
          </p>
          <p>
            2.4 The Customer is responsible for obtaining and maintaining any
            equipment and ancillary services needed to connect to, access, or
            otherwise use the Services, including, without limitation, modems,
            hardware, servers, software, operating systems, networking, web
            servers, and the like (collectively, "Equipment"). The Customer is
            also responsible for maintaining the security of the Equipment, the
            Customer's account, passwords (including but not limited to
            administrative and user passwords), and files, and for all uses of
            the Customer's account or the Equipment with or without the
            Customer's knowledge or consent.
          </p>
          <p>2.5 The Customer agrees to abide by our Acceptable Use Policy.</p>
          <h3>3. CONFIDENTIALITY; PROPRIETARY RIGHTS</h3>
          <p>
            3.1 Each party (the “Receiving Party”) understands that the other
            party (the “Disclosing Party”) has 3.1 Each party (the "Receiving
            Party") understands that the other party (the "Disclosing Party")
            has disclosed or may disclose business, technical, or financial
            information relating to the Disclosing Party's business (hereinafter
            referred to as "Proprietary Information" of the Disclosing Party).
            Proprietary Information of the Company includes non-public
            information regarding features, functionality, and performance of
            the Service. Proprietary Information of the Customer includes
            non-public data provided by the Customer to the Company to enable
            the provision of the Services ("Customer Data"). The Receiving Party
            agrees: (i) to take reasonable precautions to protect such
            Proprietary Information, and (ii) not to use (except in the
            performance of the Services or as otherwise permitted herein) or
            divulge to any third person any such Proprietary Information. The
            Disclosing Party agrees that the foregoing shall not apply
            concerning any information after five (5) years following the
            disclosure thereof or any information that the Receiving Party can
            document (a) is or becomes generally available to the public, or (b)
            was in its possession or known by it prior to receipt from the
            Disclosing Party, or (c) was rightfully disclosed to it without
            restriction by a third party, or (d) was independently developed
            without the use of any Proprietary Information of the Disclosing
            Party, or (e) is required to be disclosed by law.
          </p>
          <p>
            3.2 The Customer shall own all right, title, and interest in and to
            the Customer Data. The Company shall own and retain all right,
            title, and interest in and to (a) the Services and Software, all
            improvements, enhancements, or modifications thereto, (b) any
            software, applications, inventions, or other technology developed in
            connection with Implementation Services or support, and (c) all
            intellectual property rights related to any of the foregoing.
          </p>
          <p>
            3.3 Notwithstanding anything to the contrary, the Company shall have
            the right to collect and analyze data and other information relating
            to the provision, use, and performance of various aspects of the
            Services and related systems and technologies (including, without
            limitation, information concerning Customer Data and data derived
            therefrom). The Company will be free (during and after the term
            hereof) to (i) use such information and data to improve and enhance
            the Services and for other development, diagnostic, and corrective
            purposes in connection with the Services and other Company
            offerings, and (ii) disclose such data solely in aggregate or other
            de-identified form in connection with its business. No rights or
            licenses are granted except as expressly set forth herein.
          </p>
          <h3>4. BILLING AND PAYMENTS</h3>
          <p>
            4.1 Customer will pay Company all fees in accordance with each Order
            in accordance with the terms therein (the "Fees"). All amounts are
            non-transferable, non-refundable, and non-cancelable. Customer
            agrees that Company may bill the credit card on file for the
            Customer or other payment methods for additional users and renewals.
            If Customer's use of the Services exceeds the Service Capacity set
            forth at the time of the Order or otherwise requires the payment of
            additional fees (per the terms of this Agreement), Customer shall be
            billed for such usage and Customer agrees to pay the additional fees
            in the manner provided herein. Company reserves the right to change
            the Fees or applicable charges and to institute new charges and Fees
            at the end of the Initial Service Term or the then-current renewal
            term, upon thirty (30) days prior notice to Customer (which may be
            sent by email). If Customer believes that Company has billed
            Customer incorrectly, Customer must contact Company no later than 60
            days after the closing date on the first billing statement in which
            the error or problem appeared, in order to receive an adjustment or
            credit. Inquiries should be directed to Company's customer support
            department.
          </p>
          <p>
            4.2 Customer shall be responsible for all taxes associated with
            Services other than U.S. taxes based on Company's net income.
          </p>
          <h3>5. TERM AND TERMINATION</h3>
          <p>
            5.1 Subject to earlier termination as provided below, this Agreement
            is for the Initial Service Term as specified in the Order, and shall
            be automatically renewed for additional periods of the same duration
            as the Initial Service Term (collectively, the "Term"), unless
            either party requests termination at least thirty (30) days prior to
            the end of the then-current term. All renewals will be charged at
            the then-current rates.
          </p>
          <p>
            5.2 In addition to any other remedies it may have, either party may
            also terminate this Agreement upon thirty (30) days' notice (or
            without notice in the case of nonpayment), if the other party
            materially breaches any of the terms or conditions of this
            Agreement. Customer will pay in full for the Services up to and
            including the last day on which the Services are provided. Upon any
            termination, Company will make all Customer Data available to
            Customer for electronic retrieval for a period of thirty (30) days,
            but thereafter Company may, but is not obligated to, delete stored
            Customer Data. All sections of this Agreement which by their nature
            should survive termination will survive termination, including,
            without limitation, accrued rights to payment, confidentiality
            obligations, warranty disclaimers, and limitations of liability.
          </p>
          <h3>6. WARRANTY AND DISCLAIMER</h3>
          <p>
            Company shall use reasonable efforts consistent with prevailing
            industry standards to maintain the Services in a manner which
            minimizes errors and interruptions in the Services and shall perform
            the Implementation Services in a professional and workmanlike
            manner. Services may be temporarily unavailable for scheduled
            maintenance or for unscheduled emergency maintenance, either by
            Company or by third-party providers, or because of other causes
            beyond Company's reasonable control, but Company shall use
            reasonable efforts to provide advance notice in writing or by e-mail
            of any scheduled service disruption. HOWEVER, COMPANY DOES NOT
            WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE; NOR
            DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED
            FROM USE OF THE SERVICES. EXCEPT AS EXPRESSLY SET FORTH IN THIS
            SECTION, THE SERVICES AND IMPLEMENTATION SERVICES ARE PROVIDED "AS
            IS" AND COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY
            AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
          </p>
          <p>
            6.1 Each Party asserts and confirms that it has the authority and
            legal power to agree to these Terms and be bound by them.
          </p>
          <h3>7. INDEMNITY</h3>
          <p>
            Company shall hold Customer harmless from liability to third parties
            resulting from infringement by the Service of any United States
            patent or any copyright or misappropriation of any trade secret,
            provided Company is promptly notified of any and all threats, claims
            and proceedings related thereto and given reasonable assistance and
            the opportunity to assume sole control over defense and settlement;
            Company will not be responsible for any settlement it does not
            approve in writing. The foregoing obligations do not apply with
            respect to portions or components of the Service (i) not supplied by
            Company, (ii) made in whole or in part in accordance with Customer
            specifications, (iii) that are modified after delivery by Company,
            (iv) combined with other products, processes or materials where the
            alleged infringement relates to such combination, (v) where Customer
            continues allegedly infringing activity after being notified thereof
            or after being informed of modifications that would have avoided the
            alleged infringement, or (vi) where Customer’s use of the Service is
            not strictly in accordance with this Agreement. If, due to a claim
            of infringement, the Services are held by a court of competent
            jurisdiction to be or are believed by Company to be infringing,
            Company may, at its option and expense (a) replace or modify the
            Service to be non-infringing provided that such modification or
            replacement contains substantially similar features and
            functionality, (b) obtain for Customer a license to continue using
            the Service, or (c) if neither of the foregoing is commercially
            practicable, terminate this Agreement and Customer’s rights
            hereunder and provide Customer a refund of any prepaid, unused fees
            for the Service.
          </p>
          <h3>8. LIMITATION OF LIABILITY</h3>
          <p>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY
            OF A PERSON, COMPANY AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO
            ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES,
            REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE
            OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR
            TERMS AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE,
            STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF
            USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF
            PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF
            BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR
            CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND COMPANY’S
            REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH
            AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY
            CUSTOMER TO COMPANY FOR THE SERVICES UNDER THIS AGREEMENT IN THE 12
            MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH
            CASE, WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES.{" "}
          </p>
          <h3>9. MISCELLANEOUS</h3>
          <p>
            If any provision of this Agreement is found to be unenforceable or
            invalid, that provision will be limited or eliminated to the minimum
            extent necessary so that this Agreement will otherwise remain in
            full force and effect and enforceable. This Agreement is not
            assignable, transferable or sublicensable by Customer except with
            Company’s prior written consent. Company may transfer and assign any
            of its rights and obligations under this Agreement without consent.
            This Agreement is the complete and exclusive statement of the mutual
            understanding of the parties and supersedes and cancels all previous
            written and oral agreements, communications and other understandings
            relating to the subject matter of this Agreement, and that all
            waivers and modifications must be in a writing signed by both
            parties, except as otherwise provided herein. No agency,
            partnership, joint venture, or employment is created as a result of
            this Agreement and Customer does not have any authority of any kind
            to bind the Company in any respect whatsoever. In any action or
            proceeding to enforce rights under this Agreement, the prevailing
            party will be entitled to recover costs and attorneys’ fees. All
            notices under this Agreement will be in writing and will be deemed
            to have been duly given when received, if transmitted by e-mail; the
            day after it is sent. This Agreement shall be governed by the laws
            of the State of Delaware without regard to its conflict of laws
            provisions. Any litigation arising under this Agreement will be
            brought exclusively in the federal or state courts located in
            Delaware.
          </p>
        </div>
      </div>
    </div>
  );
}
