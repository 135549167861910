export function PrivacyContent() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto prose">
          <h1>Privacy</h1>
          <p>
            Briga Labs Privacy Statement <br />
            Effective March 1st, 2023
          </p>
          <p>
            This Privacy Policy ("Policy") describes how TwoWeeks ("we," "us,"
            or "our") collects, uses, and discloses the personal information of
            users ("you" or "user") of our TwoWeeks software product and related
            services (collectively, the "Services") in North America.
          </p>
          <p>
            By using our Services, you agree to the collection, use, and
            disclosure of your personal information in accordance with this
            Policy. If you do not agree with this Policy, please do not use our
            Services.
          </p>
          <h2>Information We Collect</h2>
          <p>
            We collect personal information you provide to us when using our
            Services, including:
          </p>
          <p>
            a. Account information: When you register for an account, we collect
            your name, email address, job title, and any other information you
            choose to provide.
          </p>
          <p>
            b. Project and collaboration information: We collect information
            related to your work projects, tasks, comments, and any other data
            you input when using our Services for collaboration purposes.
          </p>
          <p>
            We also collect information automatically from your device when you
            use our Services, including:
          </p>
          <p>
            c. Device and usage information: IP address, browser type, device
            characteristics, operating system, language preferences, referring
            URLs, device name, country, location, information about how and when
            you use our software, and other technical information.
          </p>
          <h2>How We Use Your Information</h2>
          <p>We use your personal information to:</p>
          <p>a. Provide, maintain, and improve our Services.</p>
          <p>b. Facilitate communication and collaboration among users.</p>
          <p>
            c. Analyze usage patterns and user behavior for improving the
            software's features and user experience.
          </p>
          <p>
            d. Protect the security and integrity of our Services, and prevent
            fraud or other malicious activities.
          </p>
          <p>e. Respond to customer inquiries and provide customer support.</p>
          <p>
            f. Send updates, technical notices, security alerts, and other
            administrative messages.
          </p>
          <p>
            g. Comply with legal obligations and enforce our Terms of Service.
          </p>
          <h2>How We Share Your Information</h2>
          <p>
            We do not share, sell, or rent your personal information to third
            parties for their marketing purposes. We may share your information
            with:
          </p>
          <p>
            a. Service providers who help us operate and maintain our Services,
            only to the extent necessary to provide their services to us.
          </p>
          <p>
            b. Legal authorities when required by law, or when we believe in
            good faith that such disclosure is necessary to protect our rights,
            protect your safety or the safety of others, or comply with a legal
            obligation.
          </p>
          <h2>Data Retention</h2>
          <p>
            We retain your personal information for as long as your account is
            active or as necessary to fulfill the purposes for which it was
            collected, comply with our legal obligations, resolve disputes, and
            enforce our agreements.
          </p>
          <h2>Security</h2>
          <p>
            We implement reasonable security measures to protect your personal
            information from unauthorized access, disclosure, or alteration.
            However, no method of transmission over the Internet or electronic
            storage is completely secure, so we cannot guarantee the absolute
            security of your information.
          </p>
          <h2>Your Rights and Choices</h2>
          <p>
            You have the right to access, update, or delete your personal
            information. You can exercise these rights by logging into your
            account and updating your information, or by contacting us at [your
            contact email address]. You may also unsubscribe from our
            promotional emails by clicking the "unsubscribe" link in the email.
          </p>
          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update this Policy from time to time to reflect changes in
            our practices or applicable laws. We will notify you of any material
            changes by posting the updated Policy on our website or by sending
            an email to the address associated with your account.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about this Policy, please
            contact us at terms@twoweeks.io
          </p>
          <p>
            For users located in the European Economic Area, our Data Protection
            Officer can be reached at privacy@twoweeks.io.
          </p>
        </div>
      </div>
    </div>
  );
}
