import { PlayIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

export default function TopDescription() {
  const [showVideo, setShowVideo] = useState(false);

  const renderVideo = () => (
    <div className="relative overflow-hidden pt-16 group sm:px-4">
      <video
        controls
        className="sm:rounded-2xl shadow aspect-video"
        autoPlay
        onEnded={() => setShowVideo(false)}
      >
        <source
          src="https://upload.beta-briga.com/TwoWeeks-inspire-tomorrow.mp4"
          type="video/mp4"
        />
        Download the
        <a href="https://upload.beta-briga.com/TwoWeeks-inspire-tomorrow.mp4">
          MP4
        </a>
        video.
      </video>
    </div>
  );

  const renderScreenShot = () => (
    <div className="relative overflow-hidden pt-16">
      <button
        type="button"
        onClick={() => setShowVideo(true)}
        className="absolute group flex -ml-20 flex-col p-8 items-center justify-center space-y-2 left-1/2 top-1/2"
      >
        <div className="group-hover:bg-white transition-all rounded-full group-hover:shadow group-hover:border-pink-400 p-2 border-4  border-pink-300">
          <PlayIcon className="w-12 h-12 group-hover:text-pink-400 text-pink-300 transition relative left-1" />
        </div>
        <div className="py-0.5 bg-opacity-80 bg-white px-2 rounded font-semibold transition-colors text-xl text-pink-400 group-hover:text-pink-500">
          Watch the video
        </div>
      </button>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <img
          src="/img/gantt-screenshot.png"
          alt="App screenshot"
          className="mb-[-12%] rounded-md md:rounded-xl shadow-2xl ring-1 ring-gray-900/10"
          width={2432}
          height={1442}
        />
        <div className="relative" aria-hidden="true">
          <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
        </div>
      </div>
    </div>
  );

  return (
    <div className="py-16 bg-white overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto lg:max-w-7xl">
        <div className="relative max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 ">
          <h2
            id="product"
            className="lg:pt-12 space-y-6 leading-10 font-semibold mx-auto text-center text-2xl text-gray-700 sm:text-4xl"
          >
            <div className="text-balance">
              TwoWeeks.io solves the problems that lead to cost and schedule
              overruns.
            </div>
          </h2>
          <p className="mt-6 max-w-3xl mx-auto text-center text-xl text-gray-600">
            Project navigation goes beyond managing projects. You set the end
            goal and our system tells you how to get there with precision and
            accuracy. Our AI focuses on handling the complexities of scheduling
            and delivery predictions to give you unique control and
            unprecedented insight.
          </p>
        </div>
        {showVideo ? renderVideo() : renderScreenShot()}
      </div>
    </div>
  );
}
