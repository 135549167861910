import Footer from "../../elements/Footer";
import BetaForm from "./BetaForm";
import { BetaHeader } from "./BetaHeader";

export function Beta() {
  return (
    <div>
      <BetaHeader />
      <BetaForm />
      <Footer />
    </div>
  );
}
