export default function MiddleDescription() {
  return (
    <div className="overflow-hidden">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pt-4 lg:pl-4">
            <div className="lg:max-w-lg">
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-800 sm:text-4xl">
                Easy to Learn, Easy to Use
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Managing projects can be hard. Project navigation is easy
                because we handle the complexity for you. Our simple to use,
                intuitive UI means that your teams are up and running in just
                minutes.
              </p>
              <div className="mt-8">
                <a
                  href={`${process.env.REACT_APP_WEBAPP_URI}/auth/register`}
                  className="inline-flex rounded-md bg-teal-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign up today
                </a>
              </div>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img
              src="/img/taskswitcher-screenshot.png"
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
